import React, {ForwardedRef, forwardRef, useRef, useState} from "react";

import {useCombinedRefs} from "@pg-mono/hooks";

import {TextInputBase} from "../../atoms/TextInputBase";
import {IInputWrapperProps, InputWrapper} from "../../atoms/wrapper/InputWrapper";
import {IFieldState} from "../../types";
import {IInputCoreProps, InputCore} from "./InputCore";

export type ITextInputProps = IInputWrapperProps & IInputCoreProps;
export type ITextInputOwnProps = ITextInputProps & {inputForwardedRef?: ForwardedRef<HTMLInputElement>};

export const Input = forwardRef<HTMLDivElement, ITextInputOwnProps>((props, forwardedRef) => {
    // destructuring mostly to remove some props and spread rest into input core component.
    const {fieldState, detail, onFocus, onBlur, className, inputForwardedRef, rightElement, leftElement, elementCustomStyle, ...inputCoreProps} = props;
    const localRef = useRef<HTMLInputElement>(null);
    const inputRef = useCombinedRefs<HTMLInputElement>(inputForwardedRef, localRef);

    const [isFocused, setFocused] = useState(false);

    const events = {
        onFocus: () => {
            setFocused(true);
        },
        onBlur: () => {
            setFocused(false);
        },
        onClick: (event: React.MouseEvent) => {
            event.stopPropagation();

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    };

    const innerFieldState: IFieldState = isFocused ? "focused" : fieldState ? fieldState : "default";

    return (
        <TextInputBase onClick={events.onClick} fieldState={innerFieldState} ref={forwardedRef} className={className}>
            <InputWrapper
                fieldState={innerFieldState}
                detail={detail}
                rightElement={rightElement}
                leftElement={leftElement}
                elementCustomStyle={elementCustomStyle}
            >
                <InputCore
                    {...inputCoreProps}
                    ref={inputRef}
                    onFocus={(event) => {
                        events.onFocus();
                        onFocus && onFocus(event);
                    }}
                    onBlur={(event) => {
                        events.onBlur();
                        onBlur && onBlur(event);
                    }}
                />
            </InputWrapper>
        </TextInputBase>
    );
});
