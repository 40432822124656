import {useEffect} from "react";
import {useFormikContext} from "formik";

import {useIsMounted} from "@pg-mono/hooks";

/*
 * this component exists because Formik does not provide an easy way to handle onChange callbacks
 */

// typing any: no idea on how to tie the generic prop to callback prop values
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OnChangeHandlingComponent = <ValueType extends Record<string, any>>(props: {onChange: (formValues: ValueType) => void}) => {
    const formikProps = useFormikContext<ValueType>();
    const isMounted = useIsMounted();

    useEffect(() => {
        if (isMounted) {
            props.onChange(formikProps.values);
        }
    }, [formikProps.values]);

    return null;
};
