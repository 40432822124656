import React, {FormEvent, useMemo} from "react";
import {css} from "@emotion/react";

import {display, p, w100} from "@pg-design/helpers-css";
import type {IFieldState} from "@pg-design/inputs";
import {FieldWrapper, getFieldState, InputCore, InputWrapper} from "@pg-design/inputs";
import {Select} from "@pg-design/select";

import {IFormFieldProps} from "../../get_formik_field_props";

export type IPhoneFieldValue = {
    code: number;
    number: string;
};

interface ICountryCode {
    value: number;
    label: string;
}
export interface IPhoneFieldProps extends Omit<IFormFieldProps<string, IPhoneFieldValue>, "checked"> {
    codes: ICountryCode[];
    placeholder?: string;
    className?: string;
    maxMenuHeight?: number;
    name: string;
    error?: string[] | undefined;
    onChange: (field: string, value: IPhoneFieldValue) => void;
    onClick?: (e: React.MouseEvent) => void;
    onFocus?: (e: React.FocusEvent) => void;
    fieldState?: IFieldState;
    message?: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PhoneField = <TValues extends Record<string, string>>(props: IPhoneFieldProps) => {
    const {codes, placeholder, className, maxMenuHeight, error, message, onClick, onFocus} = props;
    const options = useMemo(() => codes.map((code) => ({value: code.value, label: `${code.label} (+${code.value})`})), [props.codes]);
    const currentCode: ICountryCode | undefined = useMemo(() => options.find((option) => option.value === props.value.code), [props.value.code]);

    const onCodeChange = (code: ICountryCode) => {
        props.onChange(props.name, {...props.value, code: code.value});
    };
    const onNumberChange = (e: FormEvent<HTMLInputElement>) => {
        props.onChange(props.name, {code: props.value.code, number: e.currentTarget.value});
    };

    const fieldState = getFieldState(props);

    return (
        <FieldWrapper css={p(0)} fieldState={fieldState} message={error?.join(", ") || message} className={className} onClick={onClick}>
            <InputWrapper fieldState={fieldState} noPadding>
                <div css={[display("flex"), w100]}>
                    <Select
                        instanceId={props.name + "_country_select"}
                        id={props.name + "_country_select"}
                        css={selectStyle}
                        isBorderless
                        options={options}
                        value={currentCode}
                        onChange={(value) => {
                            onCodeChange(value as ICountryCode);
                        }}
                        onFocus={onFocus}
                        menuPlacement="auto"
                        maxMenuHeight={maxMenuHeight}
                    />
                    <InputCore
                        onChange={onNumberChange}
                        css={inputStyle}
                        placeholder={placeholder}
                        value={props.value.number}
                        type="number"
                        pattern="[0-9]*"
                        onFocus={onFocus}
                    />
                </div>
            </InputWrapper>
        </FieldWrapper>
    );
};

const selectStyle = css`
    min-width: 14rem;
    border: none;
    z-index: 10000;
    max-height: 10rem;
`;

const inputStyle = css`
    ${w100};

    /*
    * remove browser specific number selection arrows as they make no sense in a phone input
    */
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`;
