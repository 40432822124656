import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckboxHalfCheckedIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path
                fillRule="evenodd"
                stroke="none"
                d="M 8.75 1.25 L 1.25 1.25 L 1.25 8.75 L 8.75 8.75 L 8.75 1.25 Z M 7.55125 4.523917 L 2.448242 4.523917 L 2.448242 5.476083 L 7.55125 5.476083 L 7.55125 4.523917 Z"
            />
        </SvgIcon>
    );
};
