import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const CheckboxIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.917 2.083v5.834H2.083V2.083h5.834Zm.833-.833h-7.5v7.5h7.5v-7.5Z" />
        </SvgIcon>
    );
};
