import React from "react";
import {css} from "@emotion/react";

import {elevation} from "@pg-design/elevation";
import {borderRadius, p} from "@pg-design/helpers-css";

import {RangeType} from "./constants";
import {IRange} from "./create_range";

interface IProps {
    options?: {value: number; label: string}[];
    value: IRange<RangeType>;
    name: string;
    cutDirection: number;
    onChange: (name: string, value: RangeType) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    className?: string;
}

export const InputRangeOptions = (props: IProps) => {
    const {name, value, cutDirection, options = []} = props;
    const isLower = cutDirection > 0;
    const isUpper = cutDirection < 0;

    let currentValue: RangeType | "";
    let oppositeFieldValue: RangeType | "";

    if (options && value && cutDirection !== 0) {
        if (isLower) {
            currentValue = value.lower;
            oppositeFieldValue = value.upper;
        } else {
            currentValue = value.upper;
            oppositeFieldValue = value.lower;
        }
    }

    return options.length > 0 ? (
        <div css={optionWrapperStyle} className={props.className}>
            {options.map((option: {label: string; value: RangeType}) => {
                let disabled = false;
                const isAbove = isLower && oppositeFieldValue <= option.value;
                const isBelow = isUpper && oppositeFieldValue >= option.value;

                let optionLabel: string | JSX.Element = option.label;
                if ((currentValue as string | number).toString() === option.value.toString()) {
                    optionLabel = <b>{option.label}</b>;
                } else {
                    if (oppositeFieldValue !== "" && (isAbove || isBelow)) {
                        disabled = true;
                        optionLabel = <span>{option.label}</span>;
                    }
                }

                const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
                    if (!disabled) {
                        props.onChange(name, option.value);
                    }

                    props.onClick?.(event);
                };

                return oppositeFieldValue !== "" && (isAbove || isBelow) ? null : (
                    <div css={optionElementStyle} key={option.value.toString()} onClick={onClick}>
                        {optionLabel}
                    </div>
                );
            })}
        </div>
    ) : null;
};

const optionWrapperStyle = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${borderRadius()};
    ${elevation(2)};
    overflow: hidden;
`;

const optionElementStyle = css`
    ${p(1, 2)};
    cursor: pointer;
    border-bottom: 1px solid #f2f2f2;

    &:hover {
        background: #f2f2f2;
    }
`;
