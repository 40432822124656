import React from "react";
import {useTheme} from "@emotion/react";

import {getInputStyle} from "../../atoms/get_input_style";

export type ITextareaCoreProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    testid?: string;
};

export const TextareaCore = React.forwardRef<HTMLTextAreaElement, ITextareaCoreProps>((props, ref) => {
    const theme = useTheme();
    const inputStyle = getInputStyle(theme, true, props.disabled);

    const textareaRef = ref as React.ForwardedRef<HTMLTextAreaElement>;
    return <textarea ref={textareaRef} css={inputStyle} {...props} disabled={props.disabled} data-testid={props.testid} />;
});
